@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: roboto;
    src: url(./fonts/robotoslab.ttf);
  }

  @font-face {
    font-family: bluenight;
    src: url(./fonts/BluenightRegular.ttf);
  }

  @font-face {
    font-family: geliofasolada;
    src: url(./fonts/GelioFasolada.ttf);
  }

  @font-face {
    font-family: redcoat;
    src: url(./fonts/redcoat.ttf);
  }

  ::-webkit-scrollbar {
    width: 1vh;
  }

  ::-webkit-scrollbar-track {
    background-color: #c8b285;
    opacity: 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ce6c32;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #824420;
  }

  li {
    @apply p-4 font-[bluenight] uppercase text-[#814d20]
    hover:text-[#fff] cursor-pointer transition duration-300 ease-out tracking-[1.5px];
  }

  body {
    overflow-x: hidden;
    box-sizing: border-box;
    user-select: none;
  }

  img {
    user-select: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  .item {
    width: auto;
    height: auto;
    z-index: -1;
  }

  .navbar-li {
    top: 50%;
    transform: translate(0, -50%);
  }

  #artifact {
    user-select: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  #artifact:hover {
    cursor: grab;
  }

  #artifact:active {
    cursor: grabbing;
  }

  .custom-button {
    @apply text-white border bg-[#181e2a] border-[#181e2a]
    hover:bg-transparent hover:text-[#181e2a] rounded-md transition duration-300 ease-out;
  }

  .swiper-button-next {
    background-image: url("./images/roadmap/arrow.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
  }

  .swiper-button-next::after {
    display: none;
  }

  .floating {
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  .floating-x {
    animation-name: floating-x;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  @keyframes floating {
    0% {
      transform: translate(0, 0px);
    }
    50% {
      transform: translate(0, 15px);
    }
    100% {
      transform: translate(0, -0px);
    }
  }

  @keyframes floating-x {
    0% {
      transform: translate(0, 0px);
    }
    50% {
      transform: translate(15px, 0px);
    }
    100% {
      transform: translate(-0px, 0px);
    }
  }

  @media screen and (min-width: 1223px) {
    #section-staking {
      height: 90vh;
    }
  }

  @media screen and (min-width: 1221px) {
    #nft-card {
      margin-left: 5rem;
    }
  }

  @media screen and (min-width: 1159px) {
    #section-tokenomics {
      order: 1;
    }
  }

  .nft-content {

    position: absolute;
    padding: 0vw;
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hero-content {
    z-index: 3;
    position: absolute;
    padding: 0vw;
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 1077px) {
    .border-lr {
      visibility: hidden;
    }

    ::-webkit-scrollbar {
      width: 1.5vh;
    }
  }

  .mySwiperTeam:hover {
    cursor: grab;
  }

  .mySwiperTeam:active {
    cursor: grabbing;
  }

  .mySwiperTeam {
    width: 1100px;
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;

    -webkit-mask-image: linear-gradient(
      90deg,
      transparent 5%,
      rgba(0, 0, 0, 1) 12%,
      rgba(0, 0, 0, 1) 80%,
      transparent 95%
    );
  }

  .mySwiperTeam-slide {
    text-align: center;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .mySwiperTeam-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .mySwiperGame-slide img {
    display: block;
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .mySwiperTeamV2 {
    width: 1200px;
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
  }

  .mySwiperTeamV2-tablet {
    margin-top: 50px;
    width: 1100px;
    height: 500px;
    padding-top: 50px;
    padding-bottom: 50px;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
  }

  .mySwiperTeamV2-tablet-slide {
    text-align: center;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .mySwiperTeamV2-tablet-slide img {
    display: block;
    width: 300px;
    height: 500px;
  }

  .text-shadow {
    text-shadow: 2px 2px 4px #000;
  }
}

.swiper-pagination-bullet-active {
  background-color: #975a35 !important;
}

#gameplay-section {
  z-index: 1;
}

@media screen and (max-height: 700px) {
  #gameplay-section {
    height: 150vh;
  }
}

body {
  background-color: #8d7a41;
}

.faded-edges {
  -webkit-mask-image: linear-gradient(
    180deg,
    transparent 0%,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 95%,
    transparent 100%
  );
}

.faded-edgesv2 {
  -webkit-mask-image: linear-gradient(
    180deg,
    transparent 2%,
    rgba(0, 0, 0, 1) 16%,
    rgba(0, 0, 0, 1) 90%,
    transparent 100%
  );
}


.menu{
  background: white;
  height: 105vh;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal{
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sea{
  object-fit: cover;
}

.div-center{
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 99999999999999999999999999999999;
}